import { Link, useParams } from "react-router-dom";

const PartnerTypeSelection = () => {
  const { region } = useParams();

  return (
    <div>
      <h2 className="pb-8">
        Select the type of partner you would like to find
      </h2>
      <div className="flex p-4 space-x-8">
        <Link
          className="text-gray-600 border-gray-600 p-2 border-2 w-32 text-center rounded h-24 flex items-center justify-center"
          to={`/partners/${region}/nearest/physio`}
        >
          Physio
        </Link>
        <Link
          className="text-gray-600 border-gray-600 p-2 border-2 w-32 text-center rounded h-24 flex items-center justify-center"
          to={`/partners/${region}/nearest/gp`}
        >
          GP
        </Link>
      </div>
    </div>
  );
};

export default PartnerTypeSelection;

import { BuildingOffice2Icon as BuildingOfficeIcon } from "@heroicons/react/24/outline";

const PartnerListCard = ({ partner }) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <div className="hidden sm:block">
        <BuildingOfficeIcon
          className="h-10 w-10 text-axis-headings rounded-full"
          alt=""
        />
      </div>
      <div className="ml-3">
        <div className="text-sm font-medium text-gray-900">
          {partner.clinicName}
          <div
            className={classNames(
              partner.offBoarded
                ? " text-red-500 border-red-500 border-2 my-2 w-32 text-center"
                : "hidden text-gray-600 hover:bg-gray-200 hover:bg-opacity-75",
              "px-3 py-2 rounded-md text-xs font-medium"
            )}
          >
            OFF BOARDED
          </div>
        </div>
        <p className="text-sm text-gray-500">{partner.address}</p>

        <div
          className={classNames(
            partner.preferredProvider
              ? " text-green-500 border-green-500 border-2 my-2 w-32 text-center"
              : "hidden text-gray-600 hover:bg-gray-200 hover:bg-opacity-75",
            "px-3 py-2 rounded-md text-xs font-medium"
          )}
        >
          PREFERRED
        </div>

        <div>
          <label className="text-xs text-gray-400">Distance</label>
          <p>{partner.durationTravelText}</p>
          <p>{partner.distanceTravelText}</p>
        </div>

        <div>
          <label className="text-xs text-gray-400">Rating</label>
          <p>{partner.rating}</p>
        </div>

        <div>
          <label className="text-xs text-gray-400">Contacts</label>
          <p>{partner.phone}</p>
          <p>{partner.email}</p>
        </div>

        {partner.notes === "" ? null : (
          <div>
            <label className="text-xs text-gray-400">Notes</label>
            <p className="">{partner.notes}</p>
          </div>
        )}

        <div>
          <label className="text-xs text-gray-400">Website</label>
          <p className="">{partner.website}</p>
        </div>

        <div>
          <label className="text-xs text-gray-400">Partner Status</label>
          <p className="">{partner.partnerStatus}</p>
        </div>

        <div>
          <label className="text-xs text-gray-400">Partner Type</label>
          <p className="">{partner.partnerType}</p>
        </div>

        {partner.preferredProvider === true ? (
          <div>
            <label className="text-xs text-gray-400">Preferred Provider</label>
            <p className="">Yes</p>
          </div>
        ) : (
          <div>
            <label className="text-xs text-gray-400">Preferred Provider</label>
            <p className="">No</p>
          </div>
        )}

        <div>
          <label className="text-xs text-gray-400">Partner Id</label>
          <p className="">{partner.id}</p>
        </div>
      </div>
    </>
  );
};

export default PartnerListCard;

import "./App.css";
import Layout from "./Layout";
import NearestPartners from "./NearestPartners";
import PageNotFound from "./PageNotFound";

import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import PartnerTypeSelection from "./PartnerTypeSelection";
import PartnerRegionSelection from "./PartnerRegionSelection";

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>

          <Route path="/" element={<Navigate to="/partners/regions" />} />
          <Route path="/partners/regions" element={<PartnerRegionSelection />} />

          <Route path="partners/:region">

            <Route path="nearest" element={<PartnerTypeSelection />} />

            <Route path="nearest/physio" element={<NearestPartners />} />
            <Route path="nearest/gp" element={<NearestPartners />} />

          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;

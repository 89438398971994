// appConfig.js

const prod = {
  url: {
    APP_URL: "https://partners.atlas.helloaxis.com.au",
    API_URL: "https://partners.atlas.helloaxis.com.au",
  },
};
const dev = {
  url: {
    APP_URL: "http://localhost:3000",
    API_URL: "http://localhost:4000",
  },
};

export const appConfig = process.env.NODE_ENV === "development" ? dev : prod;

import { PlusCircleIcon, StarIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";

const TopPartnerCard = ({
  matchedAddress,
  topPartnerName,
  topPartnerAddress,
  topPartnerDurationText,
  topPartnerDistanceText,
  topPartnerDurationValue,
  topPartnerRating,
  topPartnerPhone,
  topPartnerEmail,
  topPartnerNotes,
  topPartnerId,
  topPartnerWebsite,
  topPartnerOffBoarded,
  topPartnerStatus,
  topPartnerType,
  topPartnerPreferredProvider,
}) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="rounded-lg mt-8 border border-gray-300 py-2 bg-gray-50 p-6 pb-6">
      <h1 className="font-bold text-xl pb-4 pt-2 text-axis-headings">
        Nearest Partner Match
      </h1>
      <div className="flex flex-col sm:flex-row justify-between items-top">
        <div className="hidden sm:flex flex-col justify-start mr-4 items-center px-4 py-3">
          {topPartnerType === "APP" ? (
            <Fragment>
              <StarIcon
                className="h-36 w-auto text-yellow-400"
                aria-hidden="true"
              />
              Physio Clinic
            </Fragment>
          ) : (
            <Fragment>
              <PlusCircleIcon
                className="h-36 w-auto text-red-400"
                aria-hidden="true"
              />
              GP Clinic
            </Fragment>
          )}
        </div>

        <div className="inline text-sm md:text-base">
          <h2 className="font-bold pb-2 text-axis-headings">Client Location</h2>

          <p>{matchedAddress}</p>
        </div>

        <div className="inline text-sm md:text-base">
          <h2 className="pt-4 sm:pt-0 font-bold pb-2 text-axis-headings">
            Partner Details
          </h2>
          <p>{topPartnerName}</p>
          <div
            className={classNames(
              topPartnerOffBoarded
                ? " text-red-500 border-red-500 border-2 my-2 w-32 text-center"
                : "hidden text-gray-600 hover:bg-gray-200 hover:bg-opacity-75",
              "px-3 py-2 rounded-md text-xs font-medium"
            )}
          >
            OFF BOARDED
          </div>

          <div
            className={classNames(
              !topPartnerPreferredProvider
                ? " text-red-500 border-red-500 border-2 my-2 w-32 text-center"
                : "hidden text-gray-600 hover:bg-gray-200 hover:bg-opacity-75",
              "px-3 py-2 rounded-md text-xs font-medium"
            )}
          >
            GENERAL
          </div>

          <div
            className={classNames(
              topPartnerPreferredProvider
                ? " text-green-500 border-green-500 border-2 my-2 w-32 text-center"
                : "hidden text-gray-600 hover:bg-gray-200 hover:bg-opacity-75",
              "px-3 py-2 rounded-md text-xs font-medium"
            )}
          >
            PREFERRED
          </div>

          <div>
            <label className="text-xs text-gray-400">Address</label>
            <p>{topPartnerAddress}</p>
          </div>

          <div>
            <label className="text-xs text-gray-400">Distance</label>
            <p>{topPartnerDurationText}</p>
            <p>{topPartnerDistanceText}</p>
            {topPartnerDurationValue > 2400 && (
              <p className="pt-1 text-axis-orange max-w-sm">
                The travel time to this clinic is more than 40 minutes. Consider
                on-boarding a new partner in the area.
              </p>
            )}
          </div>

          <div>
            <label className="text-xs text-gray-400">Rating</label>
            <p>{topPartnerRating}</p>
          </div>

          <div>
            <label className="text-xs text-gray-400">Contacts</label>
            <p>{topPartnerPhone}</p>
            <p>{topPartnerEmail}</p>
          </div>

          {topPartnerNotes === "" ? null : (
            <div>
              <label className="text-xs text-gray-400">Notes</label>
              <p className="">{topPartnerNotes}</p>
            </div>
          )}

          <div>
            <label className="text-xs text-gray-400">Website</label>
            <p className="">{topPartnerWebsite}</p>
          </div>

          <div>
            <label className="text-xs text-gray-400">Partner Status</label>
            <p className="">{topPartnerStatus}</p>
          </div>

          <div>
            <label className="text-xs text-gray-400">Partner Type</label>
            <p className="">{topPartnerType}</p>
          </div>

          {topPartnerPreferredProvider === true ? (
            <div>
              <label className="text-xs text-gray-400">
                Preferred Provider
              </label>
              <p className="">Yes</p>
            </div>
          ) : (
            <div>
              <label className="text-xs text-gray-400">
                Preferred Provider
              </label>
              <p className="">No</p>
            </div>
          )}

          <div>
            <label className="text-xs text-gray-400">Partner Id</label>
            <p className="">{topPartnerId}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopPartnerCard;

import { Link } from "react-router-dom";

const PartnerRegionSelection = () => {
  return (
    <div>
      <h2 className="pb-8">
        Select the region for your partner search
      </h2>
      <div className="flex p-4 space-x-8">
        <Link
          className="text-gray-600 border-gray-600 p-2 border-2 w-32 text-center rounded h-24 flex items-center justify-center"
          to="/partners/au/nearest"
        >
          Australia
        </Link>
        <Link
          className="text-gray-600 border-gray-600 p-2 border-2 w-32 text-center rounded h-24 flex items-center justify-center"
          to="/partners/nz/nearest"
        >
          New Zealand
        </Link>
      </div>
    </div>
  );
};

export default PartnerRegionSelection;

import { useState } from "react";
import { HomeIcon } from "@heroicons/react/20/solid";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { SpinnerCircular } from "spinners-react";
import { Link, useLocation, useParams } from "react-router-dom";

import PartnerListCard from "./PartnerListCard";
import TopPartnerCard from "./TopPartnerCard";

import { appConfig } from "./appConfig";

const NearestPartners = () => {
  const [inputAddress, setInputAddress] = useState("");
  const [results, setResults] = useState(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const { region } = useParams();

  const postAPI = (url, payload, token) => {
    const requestOptions = {
      method: "POST",
      headers: !token
        ? { "Content-Type": "application/json" }
        : {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
      body: JSON.stringify(payload),
    };

    const response = fetch(url, requestOptions);

    return response;
  };

  const findNearestPartners = async (clientAddress, partnerType, region) => {
    let payload = { clientAddress: clientAddress, partnerType: partnerType, region: region };
    const url = `${appConfig.url.API_URL}/api/partners/distance`;

    const response = await postAPI(url, payload);
    const json = await response.json();

    setResults(json);
    setLoading(false);

    if (json.status === "ERROR") {
      setError(true);
      setErrorMessage(json.partnerMatchResult);
    }
    console.log(json);
  };

  const currentLocation = useLocation();

  let pathName = currentLocation?.pathname;

  let pathComponents = pathName.split("/");
  let pathSegment = pathComponents.pop().split("?")[0] || pathComponents.pop();

  pathSegment = pathSegment.toLowerCase();

  let partnerSearchType = "APP";

  if (pathSegment === "physio") {
    partnerSearchType = "APP";
  }

  if (pathSegment === "gp") {
    partnerSearchType = "GP";
  }

  const resetPartnerFinder = () => {
    setInputAddress("");
    setResults(null);
    setInputDisabled(false);
    setError(false);
    setErrorMessage(null);
  };

  return (
    <Formik
      initialValues={{ clientAddress: inputAddress }}
      values={{ clientAddress: inputAddress }}
      validate={(values) => {
        const errors = {};
        if (!values.clientAddress) {
          errors.clientAddress = "An address is required";
        } else if (values.clientAddress.length < 4) {
          errors.clientAddress = "Addresses must be longer than 4 characters";
        } else if (values.clientAddress.length > 150) {
          errors.clientAddress = "Addresses must be less than 150 characters";
        }
        return errors;
      }}
      onSubmit={async (values) => {
        setLoading(true);
        setInputDisabled(true);
        await findNearestPartners(values.clientAddress, partnerSearchType, region);
      }}
      onReset={async () => {
        resetPartnerFinder();
      }}
    >
      {({ values, isSubmitting, isValid, handleReset }) => (
        <>
          <div className="flex items-start space-x-4">
            <div className="hidden sm:flex-shrink-0">
              <div className="ml-auto flex-shrink-0 rounded-full border-2 border-transparent bg-gray-600 p-1 text-gray-200 hover:text-white focus:outline-none">
                <HomeIcon className="h-6 w-6" aria-hidden="true" />
              </div>
            </div>
            <div className="min-w-0 flex-1">
              <Form autoComplete="off">
                <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-gray-500 focus-within:ring-1 focus-within:ring-gray-500">
                  <label htmlFor="comment" className="sr-only">
                    Enter the clients preferred address to find the nearest
                    clinic ..
                  </label>

                  <Field
                    id="clientAddress"
                    name="clientAddress"
                    disabled={inputDisabled}
                    rows={3}
                    className={
                      inputDisabled
                        ? "block w-full px-4 resize-none text-gray-300 border-0 py-6 focus:ring-0 sm:text-sm"
                        : "block w-full px-4 resize-none border-0 py-6 focus:ring-0 sm:text-sm"
                    }
                    placeholder="Enter the clients preferred address to find the nearest clinic .."
                  />

                  <div className="flex justify-between py-2 pl-3 pr-2">
                    <div className="flex items-center space-x-5">
                      <div className="flex items-center">
                        <ErrorMessage
                          name="clientAddress"
                          className="text-sm"
                          component="div"
                        />
                      </div>
                      <div className="flex items-center"></div>
                    </div>
                    <div className="flex-shrink-0">
                      <Link
                        className="text-gray-500 border-gray-400 p-2 border-2 shadow-sm text-sm font-medium mr-2 text-center rounded "
                        to="/partners/regions"
                      >
                        <span className="px-2">Back</span>
                      </Link>
                      <button
                        type="submit"
                        disabled={isSubmitting || !isValid || inputDisabled}
                        className={
                          isSubmitting || !isValid || inputDisabled
                            ? "inline-flex items-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-white shadow-sm "
                            : "inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                        }
                      >
                        Find
                      </button>
                      <button
                        onClick={handleReset}
                        disabled={values.clientAddress.length === 0}
                        className={
                          values.clientAddress.length === 0
                            ? "ml-2 hidden border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-gray-400 shadow-sm"
                            : "ml-2 inline-flex items-center rounded-md  border-gray-400 border-2  px-4 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-100 "
                        }
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </Form>

              {/** Loading Display Panel */}

              <div
                className={
                  loading
                    ? "rounded-lg mt-8 border border-gray-300 py-2 bg-gray-50"
                    : "hidden mt-8 border border-gray-300 bg-gray-50"
                }
              >
                <div className="flex justify-center items-center">
                  <SpinnerCircular
                    size={87}
                    thickness={120}
                    speed={100}
                    color="#e58659"
                    secondaryColor="rgba(0, 0, 0, 0.44)"
                  />
                </div>
                <div className="flex justify-center items-center py-2">
                  <h2>Finding closest partner clinics</h2>
                </div>
              </div>

              {/** Error Display Panel */}

              <div
                className={
                  error
                    ? "rounded-lg mt-8 border border-gray-300 py-2 bg-gray-50"
                    : "hidden mt-8 border border-gray-300 bg-gray-50"
                }
              >
                <div className="flex justify-center items-center">
                  <div className="flex justify-center items-center py-2">
                    <ExclamationCircleIcon
                      className="h-20 w-20 mr-4 text-axis-orange"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="flex flex-col">
                    <h1 className="font-bold">We hit a snag...</h1>
                    <h2>{errorMessage}</h2>
                  </div>
                </div>
              </div>

              {/** Content Panel Panel */}

              {results && !error && (
                <TopPartnerCard
                  matchedAddress={results.matchedAddress}
                  topPartnerName={results.topPartnerName}
                  topPartnerAddress={results.topPartnerAddress}
                  topPartnerDurationText={results.topPartnerDurationText}
                  topPartnerDistanceText={results.topPartnerDistanceText}
                  topPartnerDurationValue={results.topPartnerDurationValue}
                  topPartnerRating={results.topPartnerRating}
                  topPartnerPhone={results.topPartnerPhone}
                  topPartnerEmail={results.topPartnerEmail}
                  topPartnerNotes={results.topPartnerNotes}
                  topPartnerWebsite={results.topPartnerWebsite}
                  topPartnerId={results.topPartnerId}
                  topPartnerOffBoarded={results.topPartnerOffBoarded}
                  topPartnerStatus={results.topPartnerStatus}
                  topPartnerType={results.topPartnerType}
                  topPartnerPreferredProvider={
                    results.topPartnerPreferredProvider
                  }
                />
              )}

              {/** Other Matches Display Panel */}

              {results && !error && results.preferredPartners.length > 0 && (
                <div className="rounded-lg mt-8 border border-gray-300 py-2 bg-gray-50 p-6 pb-6">
                  <h1 className="font-bold text-xl pb-4 pt-2 text-axis-headings">
                    Preferred Partners Nearby
                  </h1>
                  <div className="flex flex-col sm:flex-row justify-between items-top">
                    <ul className="divide-y divide-gray-200 w-full">
                      {results.preferredPartners.map((partner, index) => {
                        if (results?.preferredPartners?.length > 0) {
                          return (
                            <li key={index} className="flex py-4 break-all">
                              <PartnerListCard partner={partner} />
                            </li>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </ul>
                  </div>
                </div>
              )}

              {results && !error && results.preferredPartners.length < 1 && (
                <div className="rounded-lg mt-8 border border-gray-300 py-2 bg-gray-50 p-6 pb-6">
                  <h1 className="font-bold text-xl pb-4 pt-2 text-axis-headings">
                    Preferred Partners Nearby
                  </h1>
                  <div className="flex flex-col sm:flex-row justify-between items-top">
                    <ul className="divide-y divide-gray-200 w-full">
                      <li key={0} className="flex py-4 break-all text-gray-600">
                        No preferred partners nearby ...
                      </li>
                    </ul>
                  </div>
                </div>
              )}

              {/** End Main Content Sections */}

              {/** Other Matches Display Panel */}

              {results && !error && results.matchedPartners.length > 0 && (
                <div className="rounded-lg mt-8 border border-gray-300 py-2 bg-gray-50 p-6 pb-6">
                  <h1 className="font-bold text-xl pb-4 pt-2 text-axis-headings">
                    General Partners Nearby
                  </h1>
                  <div className="flex flex-col sm:flex-row justify-between items-top">
                    <ul className="divide-y divide-gray-200 w-full">
                      {results.matchedPartners.map((partner, index) => {
                        if (results?.matchedPartners?.length > 0) {
                          return (
                            <li key={index} className="flex py-4 break-all">
                              <PartnerListCard partner={partner} />
                            </li>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </ul>
                  </div>
                </div>
              )}

              {results && !error && results.matchedPartners.length < 1 && (
                <div className="rounded-lg mt-8 border border-gray-300 py-2 bg-gray-50 p-6 pb-6">
                  <h1 className="font-bold text-xl pb-4 pt-2 text-axis-headings">
                    General Partners Nearby
                  </h1>
                  <div className="flex flex-col sm:flex-row justify-between items-top">
                    <ul className="divide-y divide-gray-200 w-full">
                      <li key={0} className="flex py-4 break-all text-gray-600">
                        No general partners nearby ...
                      </li>
                    </ul>
                  </div>
                </div>
              )}

              {/** End Main Content Sections */}
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

export default NearestPartners;
